<template>
	<el-dialog :title='title' :visible="addIntoVisible" @close="onClose()" width="1200px" append-to-body
		:close-on-click-modal="false">
		<h4 style="margin-left: 20px;font-size: 20px">{{ td("基础信息") }}</h4>
		<el-form ref="forms" :rules="rules" :model="form" label-position="top" label-width="120px"
			style="margin-left: 20px;">
			<el-col :span="6">
				<el-form-item :label='td("仓库")' prop="depotId" label-width="125px">
					<el-select v-model="form.depotId" :placeholder="td('请选择仓库')" style="width:96%" clearable>
						<el-option v-for="(item, index) in warhoureleList" :key="index" :label="td(item.depotName)"
							:value="item.id" />
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="6">
				<el-form-item :label='td("服务类型")' prop="vasType" label-width="125px">
					<el-select v-model="form.vasType" :placeholder="td('请选择服务类型')" style="width:96%" clearable>
						<!-- <el-option v-for="dict in tf('VAS_TYPE')" :key="dict.detailId" :label="td(dict.langTranslate)" -->
						<el-option v-for="dict in vasType" :key="dict.detailId" :label="td(dict.langTranslate)"
							:value="dict.detailId" />
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="20" style="margin:0 0 -12px 0; font-size: 20px">
				<h4>{{ td("增值服务信息") }}</h4>
			</el-col>

			<!-- 表格 -->

			<el-table :key="tableKey" :data="[form]" tooltip-effect="dark" border
				:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
				<!-- 替换产品 -->
				<div v-if="form.vasType === 5380">
					<el-table-column :label='td("换标后商品sellerSku")' align="center">
						<template slot-scope="scope">
							<el-form-item :prop="'targetGoodsList.' + scope.$index + '.targetGoodsId'"
								:rules="[{ required: true, message: ' ' }]" style="margin-top:20px">
								<el-select v-model="form.targetGoodsList[0].targetGoodsId" @change="chanSelect(form)"
									:placeholder='td("请选择seller Sku")' filterable style="width:350px">
									<el-option v-for="(item, index) in tableDataa" :key="index"
										:label="`${item.sellerSku} / ${item.depotSku} / ${item.goodsName}`"
										:value="item.goodsId" />
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label='td("原始商品sellerSku")' align="center">
						<template slot-scope="scope">
							<el-form-item prop="objectGoodsId" style="margin-top:20px">
								<el-select v-model="form.objectGoodsId" @change="chanSelect(form)"
									:placeholder='td("请选择seller Sku")' filterable style="width:350px">
									<el-option v-for="(item, index) in tableDataa" :key="index"
										:label="`${item.sellerSku} / ${item.depotSku} / ${item.goodsName}`"
										:value="item.goodsId" />
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label='td("数量")' width="150" align="center" prop="objectNumber">
						<template slot-scope="scope">
							<el-form-item prop="objectNumber" :rules="[{ required: true, message: ' ' }]"
								style="margin-top:20px">
								<!-- <el-form-item >  -->
								<el-input v-model="form.objectNumber" />
							</el-form-item>
						</template>
					</el-table-column>
				</div>
				<!-- 组合产品 -->
				<div v-if="form.vasType === 5381">
					<el-table-column :label='td("消耗数量")' width="200" align="center">
						<template slot-scope="detail">
							<div v-for="item in getNum" :key="item.goodsId">
								<span>{{ item.sellerSku }}*</span>
								<span>{{ item.number * (form.objectNumber || 0) }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label='td("目标组合商品sellerSku")' align="center">
						<template slot-scope="scope">
							<el-form-item prop="objectGoodsId" style="margin-top:20px">
								<el-select v-model="form.objectGoodsId" :placeholder='td("请选择seller Sku")' filterable
									style="width:350px">
									<el-option v-for="(item, index) in tableDataa" :key="index"
										:label="`${item.sellerSku} / ${item.depotSku} / ${item.goodsName}`"
										:value="item.goodsId" />
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label='td("组合数量")' width="150" align="center" prop="objectNumber">
						<template slot-scope="scope">
							<el-form-item prop="objectNumber" style="margin-top:20px">
								<!-- <el-form-item >  -->
								<el-input v-model="form.objectNumber" />
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label='td("消耗商品sellerSku")' width="200" align="center">
						<template slot-scope="detail">
							<div v-for="item in getNum" :key="item.goodsId">
								<span>{{ item.sellerSku }}*</span>
								<span>{{ item.number }}</span>
							</div>
						</template>
					</el-table-column>
				</div>
				<!-- 拆分产品 -->
				<div v-if="form.vasType === 5382">
					<el-table-column :label='td("拆分后数量")' width="200" align="center">
						<template slot-scope="detail">
							<div v-for="item in getNum" :key="item.goodsId">
								<span>{{ item.sellerSku }}*</span>
								<span>{{ item.number * (form.objectNumber || 0) }}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label='td("拆分商品sellerSku")' align="center">
						<template slot-scope="scope">
							<el-form-item prop="objectGoodsId" style="margin-top:20px">
								<el-select v-model="form.objectGoodsId" :placeholder='td("请选择seller Sku")' filterable
									style="width:350px">
									<el-option v-for="(item, index) in tableDataa" :key="index"
										:label="`${item.sellerSku} / ${item.depotSku} / ${item.goodsName}`"
										:value="item.goodsId" />
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label='td("拆分数量")' width="150" align="center" prop="objectNumber">
						<template slot-scope="scope">
							<el-form-item prop="objectNumber" :rules="[{ required: true, message: ' ' }]"
								style="margin-top:20px">
								<!-- <el-form-item >  -->
								<el-input v-model="form.objectNumber" />
							</el-form-item>
						</template>
					</el-table-column>
					<el-table-column :label='td("拆分后商品sellerSku")' width="200" align="center">
						<template slot-scope="detail">
							<div v-for="item in getNum" :key="item.goodsId">
								<span>{{ item.sellerSku }}*</span>
								<span>{{ item.number }}</span>
							</div>
						</template>
					</el-table-column>
				</div>
				<!-- 产品拍照 || 产品核实尺寸重量 -->
				<div v-if="form.vasType === 5383 || form.vasType === 5384">
					<el-table-column :label='td("备注")' align="center">
						<template slot-scope="scope">
							<el-input v-model="form.remark" placeholder="请输入内容"></el-input>
						</template>
					</el-table-column>
					<el-table-column :label='td("商品sellerSku")' align="center">
						<template slot-scope="scope">
							<el-form-item prop="objectGoodsId" style="margin-top:20px">
								<el-select v-model="form.objectGoodsId" :placeholder='td("请选择seller Sku")' filterable
									style="width:350px">
									<el-option v-for="(item, index) in tableDataa" :key="index"
										:label="`${item.sellerSku} / ${item.depotSku} / ${item.goodsName}`"
										:value="item.goodsId" />
								</el-select>
							</el-form-item>
						</template>
					</el-table-column>
				</div>
			</el-table>

		</el-form>
		<span slot="footer" class="footet">
			<el-button @click="onClose()" type="primary" plain>{{ td('取 消') }}</el-button>
			<el-button @click="comit(form)" type="primary" style="margin-right: -6px;" :disabled="finish">{{
				td('确定') }}</el-button>
		</span>
	</el-dialog>
</template>

<script>
import {
	insertVasOrder,
	editVasOrder
} from '@/api/vasOrder'
export default {
	name: 'addInto',
	props: ['warhoureleList', 'tableDataa'],
	data() {
		return {
			addIntoVisible: false,
			tableKey: 1,
			select: '',
			finish: false,
			form: {
				//   [5380:替换产品],[5381:组合产品],[5382:拆分产品],[5383:产品拍照],[5384:产品核实尺寸重量]
				targetGoodsList: []
			},
			formInitial: {
				targetGoodsList: []
			},
			vasType: [{ langTranslate: '替换产品', detailId: 5380 }, { langTranslate: '组合产品', detailId: 5381 }, { langTranslate: '拆分产品', detailId: 5382 }, { langTranslate: '产品拍照', detailId: 5383 }, { langTranslate: '产品核实尺寸重量', detailId: 5384 }],
			rules: {
				depotId: [{
					required: true,
					message: this.td("仓库不能为空"),
					trigger: "blur"
				}],
				vasType: [{
					required: true,
					message: this.td("服务类型不能为空"),
					trigger: "blur"
				}],
				objectGoodsId: [{
					required: true,
					message: ' ',
					trigger: "blur"
				}],
				objectNumber: [{
					required: true,
					message: ' ',
					trigger: "blur"
				}],
			},
			title: '',
		}
	},
	//国际化页面刷新不会失效
	computed: {
		getNum() {
			let objectGoodsId = this.form.objectGoodsId;
			if (!objectGoodsId) return []
			let tableDataa = this.tableDataa;
			let curItem = tableDataa.find(item => item.goodsId == objectGoodsId)
			return curItem.goodsDetail || []
		}
	},
	methods: {
		/** 新增 */
		add() {
			this.title = this.td('新增增值服务单')
			let obj = {}
			this.form.targetGoodsList.push(obj)
			// this.$set(this.form.targetGoodsList,0,{})
			this.addIntoVisible = true;
		},
		edit(data) {
			this.title = this.td('编辑增值服务单')
			let targetGoodsList = data.targetGoodsList
			if (targetGoodsList && targetGoodsList.length) {
				data.targetGoodsList.forEach(element => {
					element.targetGoodsId = element.targetGoodsId + ''
				});
			}
			this.form = {
				...data,
				objectGoodsId: data.objectGoodsId + '',
				remark:data.objectRemark
			};
			this.addIntoVisible = true;
		},
		onClose() {
			// this.form = JSON.parse(JSON.stringify(this.formInitial));
			this.form = {
				targetGoodsList: []
			}
			this.$refs.forms.clearValidate();
			this.addIntoVisible = false
		},
		chanSelect(params) {
			if (params.objectGoodsId == params.targetGoodsList[0].targetGoodsId) {
				return (
					this.$message.warning(this.td('请勿重复选择！')), this.form.targetGoodsList[0].targetGoodsId = ''
				)
			}
		},
		/** 确认，根据 inorderId是否为空判断走新增接口还是编辑接口 */
		comit() {
			this.$refs.forms.validate(valid => {
				if (valid) {
					this.finish = true;
					let form = this.form
					// 组合产品 || 拆分产品
					if (form.vasType === 5381 || form.vasType === 5382) {
						form.targetGoodsList = this.getNum.map(item => ({ targetGoodsId: item.goodsId, targetNumber: item.number * form.objectNumber, vasOrderObjectDetailId : item.vasOrderObjectDetailId }))
					}
					// 产品拍照 || 产品核实尺寸重量
					if (form.vasType === 5383 || form.vasType === 5384) {
						delete form.targetGoodsList
					}

					if (form.vasOrderObjectId) {
						// 編輯
						editVasOrder(form).then(res => {
							if (res.code === 200) {
								this.$emit('select');
								this.$message.success(this.td("编辑成功"));
								this.form = { targetGoodsList: [] };
								this.addIntoVisible = false
								this.finish = false
							} else {
								this.finish = false
								return this.$message.error(this.td(res.msg));
							}
						}).catch(()=>{
							this.finish = false
						})
					} else {
						insertVasOrder(form).then(res => {
							if (res.code === 200) {
								this.$emit('select');
								this.$message.success(this.td("新增成功"));
								this.form = { targetGoodsList: [] };
								this.addIntoVisible = false
								this.finish = false
							} else {
								this.finish = false
								return this.$message.error(this.td(res.msg));
							}
						}).catch(()=>{
							this.finish = false
						})
					}
				}
			})
		},
	}
}
</script>

<style scoped>
::v-deep .el-dialog {
	margin: 5vh auto !important;
}

::v-deep .el-dialog__body {
	padding: 5px 0;
	height: 62vh;
	overflow: auto;
}

.el-table ::v-deep .el-form-item__content {
	margin-left: 0 !important;
}
</style>
