<template>
    <!-- 主体内容区 -->
    <div>
        <div class="search">
            <el-form :model="query" ref="query" :inline="true" label-position="left" :style="{ height: scrollerHeight }">
                <div class="box">
                    <el-form-item :label="td('创建时间')">
                        <el-select v-model="labelValue" placeholder="请选择" slot="label" style="width:100px;">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                        <el-date-picker v-model="timeScope" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
                            :default-time="['00:00:00', '23:59:59']" range-separator="-" :start-placeholder="td('开始日期')"
                            :end-placeholder="td('结束日期')" size="small" clearable>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label=" ">
                        <el-input :placeholder="td('增值服务单号')" size="small" v-model="query.vasOrderNo" clearable />
                    </el-form-item>
                    <el-form-item label=" ">
                        <el-select style="width:96%" v-model="query.vasType" :placeholder="td('服务类型')" clearable>
                            <el-option v-for="item in tf('VAS_TYPE')" :key="item.detailId" :label="td(item.langTranslate)"
                                :value="item.detailId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" ">
                        <el-select style="width:96%" v-model="query.erpVasState" :placeholder="td('状态')" clearable>
                            <el-option v-for="item in tf('ERP_VAS_STATE')" :key="item.detailId"
                                :label="td(item.langTranslate)" :value="item.detailId" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label=" ">
                        <el-select style="width:96%" v-model="query.depotId" :placeholder="td('仓库')" clearable>
                            <el-option v-for="(item, index) in warhoureleList" :key="index" :label="td(item.depotName)"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-show="showSearchs" class="ItemShow" style="margin: 24px 0 0 0;">
                        <template>
                            <!-- <el-form-item :label="td('状态')">
								<el-select style="width:96%" v-model="query.state" :placeholder="td('请选择类型')" clearable>
									<el-option v-for="item in tf('ERP_INORDER_STATE')" :key="item.detailId"
											:label="td(item.langTranslate)" :value="item.detailId" />
								</el-select>
							</el-form-item> -->
                        </template>
                    </el-form-item>
                </div>
                <div class="btns">
                    <!-- <el-link class="btn11" type="primary" @click="toogleExpands"><i :class="icon"></i>{{ td(font) }}
                    </el-link> -->
                    <el-button type="primary" size="small" @click="getinorder">{{ td("搜索") }}</el-button>
                    <el-button size="small" @click="reast" style="margin-left:16px">{{ td("重置") }}</el-button>
                </div>
            </el-form>
        </div>
        <div class="footbox">
            <div class="new_button">
                <el-button type="primary" size="small" @click="addInto">{{ td("新增") }}</el-button>
            </div>

            <!-- 表格 -->
            <el-table ref="table" :data="inorderList" :header-cell-style="{ background: '#F9FCFF', color: '#323233' }"
                tooltip-effect="dark" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                row-key="vasOrderId">
                <el-table-column :label='td("增值服务单号")' prop="billNo" width="200">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="toogleExpand(scope.row)">
                            {{ scope.row.vasOrderNo }}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column :label='td("服务类型")' prop="depotId">
                    <template slot-scope="scope">
                        {{ tfp('VAS_TYPE', scope.row.vasType) }}
                    </template>
                </el-table-column>
                <el-table-column :label='td("状态")' prop="receDepot">
                    <template slot-scope="scope">
                        {{ tfp('ERP_VAS_STATE', scope.row.erpVasState) }}
                    </template>
                </el-table-column>
                <el-table-column :label='td("仓库信息 ")'>
                    <template slot-scope="scope">
                        {{ td(showDepotList(scope.row.depotId)) }}
                    </template>
                </el-table-column>
                <el-table-column :label='td("创建时间")' prop="createTime" />
                <el-table-column :label='td("完成时间")' prop="finishTime" />
                <el-table-column :label='td("操作")' fixed="right" width="150px">
                    <!-- 已创建5385 已提交5386 -->
                    <template slot-scope="scope" v-if="scope.row.erpVasState === 5385">
                        <el-button type="text" icon="el-icon-edit" @click="updateInorder(scope.row)">{{ td("编辑")
                        }}</el-button>
                        <el-button type="text" @click="detailInorder(scope.row)">{{ td("提交")
                        }}</el-button>
                        <el-button type="text" icon="el-icon-delete" @click="open(scope.row.vasOrderId)">{{ td("删除") }}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column type="expand" width="1">
                    <template slot-scope="scope">
                        <el-table :data="allotDetailMap.get(scope.row.vasOrderId)" row-key="objectGoodsId"
                            :key="detailTableKey" class="table-in-table" style="width: 90%; margin: 0 auto" border>
                            <!-- 替换产品 -->
                            <div v-if="scope.row.vasType === 5380">
                                <el-table-column :label='td("换标后商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <span>{{ detail.row.targetGoodsList[0].targetSellerSku }}/</span>
                                        <span>{{ detail.row.targetGoodsList[0].targetDepotSku }}/</span>
                                        <span>{{ detail.row.targetGoodsList[0].targetGoodsName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label='td("原始商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <span>{{ detail.row.objectSellerSku }}/</span>
                                        <span>{{ detail.row.objectDepotSku }}/</span>
                                        <span>{{ detail.row.objectGoodsName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label='td("数量")' align="center" prop="objectNumber" />
                            </div>
                            <!-- 组合产品 -->
                            <div v-if="scope.row.vasType === 5381">
                                <el-table-column :label='td("消耗数量")' align="center">
                                    <template slot-scope="detail">
                                        <div v-for="item in detail.row.targetGoodsList" :key="item.targetGoodsId">
                                            <span>{{ item.targetSellerSku }}*</span>
                                            <span>{{ item.targetNumber }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label='td("目标组合商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <span>{{ detail.row.objectSellerSku }}/</span>
                                        <span>{{ detail.row.objectDepotSku }}/</span>
                                        <span>{{ detail.row.objectGoodsName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label='td("组合数量")' align="center" prop="objectNumber" />
                                <el-table-column :label='td("消耗商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <div v-for="item in detail.row.targetGoodsList" :key="item.targetGoodsId">
                                            <span>{{ item.targetSellerSku }}*</span>
                                            <span>{{ item.sellerSkuNumber }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </div>
                            <!-- 拆分产品 -->
                            <div v-if="scope.row.vasType === 5382">
                                <el-table-column :label='td("拆分后数量")' align="center">
                                    <template slot-scope="detail">
                                        <div v-for="item in detail.row.targetGoodsList" :key="item.targetGoodsId">
                                            <span>{{ item.targetSellerSku }}*</span>
                                            <span>{{ item.targetNumber }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column :label='td("拆分商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <span>{{ detail.row.objectSellerSku }}/</span>
                                        <span>{{ detail.row.objectDepotSku }}/</span>
                                        <span>{{ detail.row.objectGoodsName }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column :label='td("拆分数量")' align="center" prop="objectNumber" />
                                <el-table-column :label='td("拆分后商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <div v-for="item in detail.row.targetGoodsList" :key="item.targetGoodsId">
                                            <span>{{ item.targetSellerSku }}*</span>
                                            <span>{{ item.sellerSkuNumber }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </div>
                            <!-- 产品拍照 || 产品核实尺寸重量 -->
                            <div v-if="scope.row.vasType === 5383 || scope.row.vasType === 5384">
                                <el-table-column :label='td("备注")' align="center" prop="objectRemark" />
                                <el-table-column :label='td("商品sellerSku")' align="center">
                                    <template slot-scope="detail">
                                        <span>{{ detail.row.objectSellerSku }}/</span>
                                        <span>{{ detail.row.objectDepotSku }}/</span>
                                        <span>{{ detail.row.objectGoodsName }}</span>
                                    </template>
                                </el-table-column>
                            </div>
                        </el-table>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <pagination :total="td(total)" :page.sync="query.pageNum" :limit.sync="query.pageSize" @pagination="getinorder" />


        <!-- 弹窗组件 -->
        <addInto ref="addIntl" :warhoureleList="warhoureleList" :tableDataa="tableDataa" @select="getinorder" />
    </div>
</template>

<script>
import addInto from "./dialog/addInto.vue";
import { mapGetters } from 'vuex'
import {
    getListtt,
} from "@/api/warehouse/index"

import { getList, getVasOrderDetail, getVasGoods, queryEditVasOrderDetail, deleteVasOrder, uploadVasOrder } from "@/api/vasOrder/index"

export default {
    name: "vasOrder",
    components: {
        addInto,
    },
    data() {
        return {
            total: 0,
            query: {
                vasOrderNo: null,
                depotId: null,
                vasType: null,
                erpVasState: null,
                pageNum: 1,
                pageSize: 10,
            },
            inorderList: [],
            warhoureleList: [],
            tableDataa: [],
            detailTableKey: false,
            allotDetailMap: new Map(),
            font: "更多",
            icon: "el-icon-arrow-down",
            showSearchs: false,
            options: [{
                value: 'create',
                label: '创建时间'
            }, {
                value: 'finish',
                label: '完成时间'
            },],
            labelValue: 'create',
            timeScope: []
        };
    },
    created() {
        this.getinorder()
        this.getdeptList()
        this.getSonlist()
    },
    computed: {
        //显示仓库名字
        showDepotList: function () {
            return function (number) {
                for (let i = 0; i < this.warhoureleList.length; i++) {
                    if (number == this.warhoureleList[i].id) {
                        return this.warhoureleList[i].depotName;
                    }
                }
            };
        },
        scrollerHeight: function () {
            return this.showSearchs ? 160 + "px" : 80 + "px";
        },
        ...mapGetters(['userid']),
    },
    methods: {
        toogleExpands() {
            if (this.showSearchs) {
                this.showSearchs = !this.showSearchs;
                this.icon = "el-icon-arrow-down";
                this.font = this.td("更多")
            } else {
                this.showSearchs = !this.showSearchs;
                this.font = this.td("收起")
                this.icon = "el-icon-arrow-up";
            }
        },
        //查询
        getinorder() {
            let parme = {
                ...this.query,
            }
            if (this.timeScope.length) {
                parme[this.labelValue + 'StartTime'] = this.timeScope[0]
                parme[this.labelValue + 'EndTime'] = this.timeScope[1]
            }
            getList(parme).then(res => {
                this.inorderList = res.rows;
                this.total = res.total
            });
        },
        //查询仓库信息
        getdeptList() {
            getListtt().then((res) => {
                this.warhoureleList = res.data;
            });
        },
        getSonlist() {
            getVasGoods({ ownerId: this.userid }).then(res => {
                this.tableDataa = res.data;
            })
        },
        //重置
        reast() {
            this.query = {
                vasOrderNo: null,
                depotId: null,
                vasType: null,
                erpVasState: null,
                pageNum: 1,
                pageSize: 10,
            }
            this.timeScope = [];
            this.getinorder()
        },
        updateInorder(data) {
            queryEditVasOrderDetail({ vasOrderId: data.vasOrderId, erpVasState: data.erpVasState }).then(res => {
                this.$refs['addIntl'].edit({ ...res.data, vasOrderObjectId: data.vasOrderId, vasOrderId: data.vasOrderId });
            })
        },
        async detailInorder(data) {
            const confirmResult = await this.$confirm(this.td("是否提交服务单?"), this.td("提示"), {
                confirmButtonText: this.td("确定"),
                cancelButtonText: this.td("取消"),
                type: "warning",
            }).catch((err) => err);
            if (confirmResult !== "confirm") return
            uploadVasOrder({ vasOrderId: data.vasOrderId, erpVasState: data.erpVasState }).then((res) => {
                if (res.code !== 200) {
                    return this.$message.error(this.td("提交失败"));
                }
                this.$message.success(this.td("提交成功"));
                this.getinorder();
            });
        },
        async open(id) {
            const confirmResult = await this.$confirm(this.td("是否删除服务单?"), this.td("提示"), {
                confirmButtonText: this.td("确定"),
                cancelButtonText: this.td("取消"),
                type: "warning",
            }).catch((err) => err);
            if (confirmResult !== "confirm") return
            deleteVasOrder({ vasOrderId: id }).then((res) => {
                if (res.code !== 200) {
                    return this.$message.error(this.td("删除失败"));
                }
                this.$message.success(this.td("删除成功"));
                this.getinorder();
            });
        },
        getAllotDetail(vasOrderId) {
            //根据 deopid 获取子表
            getVasOrderDetail({ vasOrderId }).then(res => {
                // 使用 map 结构的方式保存子表
                this.allotDetailMap.set(vasOrderId, [res.data]);
                this.detailTableKey = !this.detailTableKey;
            })
        },
        //点击母商品名，显示子SKU
        toogleExpand(val) {
            let $table = this.$refs.table;
            $table.toggleRowExpansion(val);
            this.getAllotDetail(val.vasOrderId)
        },
        addInto() {
            this.$refs['addIntl'].add();
        },
    }
}
</script>

<style scoped>
.search {
    position: relative;
    background-color: #fff;
}

.search .box {
    position: absolute;
    top: 20px;
}

.search .el-form {
    padding: 0 16px;
    color: #333333;
    margin-right: 0;
    border-bottom: 16px solid #F1F3FF;
}

.search .el-form-item {
    margin-bottom: 0;
    margin-right: 0;
}

.search ::v-deep .el-form-item__label {
    margin-right: 24px;
    padding: 0;
}

.search ::v-deep .el-input__inner {
    width: 220px;
}

.search ::v-deep .el-input__inner {
    height: 32px;
}

.btns {
    position: absolute;
    top: 24px;
    right: 16px;
}

.btns .el-button {
    width: 74px;
    height: 32px;
}

.btns .btn11,
.btn22 {
    margin-right: 16px;
}

.serch .el-form {
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    padding: 0 0 0 16px;
    background-color: #fff;
}

.serch .el-form-item {
    transform: translateY(20px);
    margin-right: 0;
}

.serch ::v-deep .el-form-item__label:nth-child(n-2) {
    margin: 0 24px 0 32px;
    padding: 0;
}

.serch .el-form-item:nth-child(1) ::v-deep .el-form-item__label:nth-child(n-2) {
    margin: 0 24px 0 0px;
    padding: 0;
}

.serch ::v-deep .el-input__inner {
    width: 220px;
}

.footbox {
    background-color: #fff;
    margin-top: 16px;
}

.new_button {
    transform: translate(16px, 24px);
    height: 80px;
}

.serch .el-button {
    width: 74px;
    height: 32px;
}

.el-table {
    padding: 0 16px;
}

@media screen and (max-width: 1800px) {
    .serch ::v-deep .el-input__inner {
        width: 150px;
        height: 32px;
    }

}

/* 110% */
@media screen and (max-width: 1800px) {
    .search ::v-deep .el-input__inner {
        width: 200px;
    }

    .search ::v-deep .el-form-item__label {
        margin-right: 12px;
    }

    .el-form-item:not(:first-child) {
        margin-left: 16px;
    }

    .btns {
        position: absolute;
        top: 24px;
        right: 0px;
    }

    .btns .btn11 {
        margin-right: 16px;
        /* transform: translate(23px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(23px, 0) scale(0.65, 0.65);
      -moz-transform: translate(23px, 0) scale(0.65, 0.65);
      -o-transform: translate(23px, 0) scale(0.65, 0.65); */
    }

    .btns .btn22 {
        margin-right: 16px;
        /* transform: translate(18px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(18px, 0) scale(0.65, 0.65);
      -moz-transform: translate(18px, 0) scale(0.65, 0.65);
      -o-transform: translate(18px, 0) scale(0.65, 0.65); */
    }

    .btns .btn33 {
        margin-right: 16px;
        /* transform: scale(0.65, 0.65);
      -webkit-transform: scale(0.65, 0.65);
      -moz-transform: scale(0.65, 0.65);
      -o-transform: scale(0.65, 0.65); */
    }

    /* .btn1 {
      transform: translate(-15px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
      -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
      -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    }
    .btn2 {
      transform: translate(20px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(20px, 0) scale(0.65, 0.65);
      -moz-transform: translate(20px, 0) scale(0.65, 0.65);
      -o-transform: translate(20px, 0) scale(0.65, 0.65);
    }
    .btn3 {
      transform: translate(-15px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(60px, 0) scale(0.65, 0.65);
      -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
      -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    } */
}

/* 125% */
@media screen and (max-width: 1620px) {
    .search ::v-deep .el-input__inner {
        width: 160px;
    }

    .search ::v-deep .el-form-item__label {
        margin-right: 12px;
    }

    .el-form-item:not(:first-child) {
        margin-left: 16px;
    }

    .btns {
        position: absolute;
        top: 24px;
        right: 0px;
    }

    .btns .btn11 {
        margin-right: 0px;
        transform: translate(23px, 0) scale(0.85, 0.85);
        -webkit-transform: translate(10px, 0) scale(0.85, 0.85);
        -moz-transform: translate(23px, 0) scale(0.85, 0.85);
        -o-transform: translate(23px, 0) scale(0.85, 0.85);
    }

    .btns .btn22 {
        margin-right: 16px;
        transform: translate(16px, 0) scale(0.85, 0.85);
        -webkit-transform: translate(16px, 0) scale(0.85, 0.85);
        -moz-transform: translate(16px, 0) scale(0.85, 0.85);
        -o-transform: translate(16px, 0) scale(0.85, 0.85);
    }

    .btns .btn33 {
        transform: scale(0.85, 0.85);
        -webkit-transform: translate(0px, 0) scale(0.85, 0.85);
        -moz-transform: scale(0.85, 0.85);
        -o-transform: scale(0.85, 0.85);
    }

    .btn1 {
        transform: translate(-5px, 0) scale(0.85, 0.85);
        -webkit-transform: translate(-5px, 0) scale(0.85, 0.85);
        -moz-transform: translate(-5px, 0) scale(0.85, 0.85);
        -o-transform: translate(-5px, 0) scale(0.85, 0.85);
    }

    .btn2 {
        transform: translate(20px, 0) scale(0.85, 0.85);
        -webkit-transform: translate(7px, 0) scale(0.85, 0.85);
        -moz-transform: translate(20px, 0) scale(0.85, 0.85);
        -o-transform: translate(20px, 0) scale(0.85, 0.85);
    }

    .btn3 {
        transform: translate(28px, 0) scale(0.85, 0.85);
        -webkit-transform: translate(28px, 0) scale(0.85, 0.85);
        -moz-transform: translate(28px, 0) scale(0.85, 0.85);
        -o-transform: translate(28px, 0) scale(0.85, 0.85);
    }
}

/* 150% */
@media screen and (max-width: 1500px) {
    .search ::v-deep .el-input__inner {
        width: 160px;
    }

    .search ::v-deep .el-form-item__label {
        margin-right: 16px;
    }

    .el-form-item:not(:first-child) {
        margin-left: 0px;
    }

    .item1 {
        transform: translate(-30px, 0) scale(0.85, 0.85);
        -webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
        -moz-transform: translate(-30px, 0) scale(0.85, 0.85);
        -o-transform: translate(-30px, 0) scale(0.85, 0.85);
    }

    .item2 {
        transform: translate(-67px, 0) scale(0.75, 0.75);
        -webkit-transform: translate(-67px, 0) scale(0.75, 0.75);
        -moz-transform: translate(-67px, 0) scale(0.75, 0.75);
        -o-transform: translate(-67px, 0) scale(0.75, 0.75);
    }

    .item3 {
        transform: translate(-104px, 0) scale(0.75, 0.75);
        -webkit-transform: translate(-104px, 0) scale(0.75, 0.75);
        -moz-transform: translate(-104px, 0) scale(0.75, 0.75);
        -o-transform: translate(-104px, 0) scale(0.75, 0.75);
    }

    .item4 {
        transform: translate(-143px, 0) scale(0.75, 0.75);
        -webkit-transform: translate(-143px, 0) scale(0.75, 0.75);
        -moz-transform: translate(-143px, 0) scale(0.75, 0.75);
        -o-transform: translate(-143px, 0) scale(0.75, 0.75);
    }

    .btns {
        position: absolute;
        top: 24px;
        right: 0px;
    }

    .btns .btn11 {
        margin-right: 0px;
        transform: translate(35px, 0) scale(0.65, 0.65);
        -webkit-transform: translate(35px, 0) scale(0.65, 0.65);
        -moz-transform: translate(35px, 0) scale(0.65, 0.65);
        -o-transform: translate(35px, 0) scale(0.65, 0.65);
    }

    .btns .btn22 {
        margin-right: 0px;
        transform: translate(28px, 0) scale(0.65, 0.65);
        -webkit-transform: translate(28px, 0) scale(0.65, 0.65);
        -moz-transform: translate(28px, 0) scale(0.65, 0.65);
        -o-transform: translate(28px, 0) scale(0.65, 0.65);
    }

    .btns .btn33 {
        transform: translate(13px, 0) scale(0.65, 0.65);
        -webkit-transform: translate(13px, 0) scale(0.65, 0.65);
        -moz-transform: translate(13px, 0) scale(0.65, 0.65);
        -o-transform: translate(13px, 0) scale(0.65, 0.65);
    }

    .btn1 {
        transform: translate(-15px, 0) scale(0.65, 0.65);
        -webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
        -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
        -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    }

    .btn2 {
        transform: translate(18px, 0) scale(0.65, 0.65);
        -webkit-transform: translate(18px, 0) scale(0.65, 0.65);
        -moz-transform: translate(18px, 0) scale(0.65, 0.65);
        -o-transform: translate(18px, 0) scale(0.65, 0.65);
    }

    .btn3 {
        transform: translate(60px, 0) scale(0.65, 0.65);
        -webkit-transform: translate(60px, 0) scale(0.65, 0.65);
        -moz-transform: translate(60px, 0) scale(0.65, 0.65);
        -o-transform: translate(60px, 0) scale(0.65, 0.65);

    }
}

@media screen and (min-width: 2510px) {
    .item4 {
        margin-right: 32px !important;
    }
}

@media screen and (min-width: 2561px) {
    .ItemShow {
        margin: 0 0 0 0px !important;
    }
}
</style>
