import request from '@/utils/request'
import { getToken } from "@/utils/auth"

// ERP增值服务列表查询
export function getList(query) {
  return request({
    url: '/erp/erpVasOrder/list',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// ERP增值列表订单详情
export function getVasOrderDetail(query) {
  return request({
    url: '/erp/erpVasOrder/detail',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// ERP增值服务商品列表（服务单新增或者修改都用这个）
export function getVasGoods(query) {
  return request({
    url: '/erp/erpVasOrder/vasGoods',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params: query
  })
}

// ERP新增增值服务单（草稿状态）
export function insertVasOrder(data) {
  return request({
    url: '/erp/erpVasOrder/insertVasOrder',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data
  })
}

// 增值订单编辑数据回显接口
export function queryEditVasOrderDetail(params) {
  return request({
    url: '/erp/erpVasOrder/queryEditVasOrderDetail',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params
  })
}

// 删除订单(草稿状态的)
export function deleteVasOrder(params) {
  return request({
    url: '/erp/erpVasOrder/deleteVasOrder',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params
  })
}

// 增值服务单草稿提交
export function uploadVasOrder(params) {
  return request({
    url: '/erp/erpVasOrder/uploadVasOrder',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    params
  })
}

// 增值订单编辑保存
export function editVasOrder(data) {
  return request({
    url: '/erp/erpVasOrder/editVasOrder',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data
  })
}

